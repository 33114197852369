<template>
	<div class="admin-students-list-page">
		<v-row justify="center">
			<v-col md="6">			
				<v-card-title class="justify-center">
					<h2 class="grey--text text--darken-2"> {{ title }} </h2>
				</v-card-title>
				<v-card-text>
					<v-form
						v-model="form"
						ref="form"
						@submit.prevent
					>
						<v-container>
							<v-row justify="center" class="mb-1">
								<v-col cols="12" md="6">
									<h2 class="text-center">
										Sample Excel File
									</h2>
								</v-col>
							</v-row>
							<v-row justify="center" class="mb-1">
								<v-col cols="12" md="10">
									<h3> Download this sample file and use the same headers to fill records </h3>
								</v-col>
							</v-row>

							<v-row justify="center" class="mb-2">
								<v-col cols="12" md="4" sm="6" >
									<!-- <download-excel
										:data="excelData ? excelData.dummy_data : null"
										:fields="getExportFields()"
										name="SampleImportExcel"
									> -->
										<v-btn
											:loading="export_in_progress"
											:disabled="export_in_progress || isLoading || !excelData"
											color="primary"
											elevation="1"
											:href="staticFile"
											block
										>
											Download
											<v-icon right dark> mdi-export </v-icon>
											<template v-slot:loader>
												<span class="custom-loader">
													<v-icon light>mdi-cached</v-icon>
												</span>
											</template>
										</v-btn>
									<!-- </download-excel> -->
								</v-col>
								<v-col cols="12" md="4" sm="6" >
									<v-btn
										block
										color="primary"
										outlined
										@click="showInstructionsDialog = true"
										:disabled="isLoading || !excelData"
									>
										Show Instructions
									</v-btn>
								</v-col>
							</v-row>

							<v-row justify="center" class="">
								<v-col cols="12" md="4">
									<h2 class="text-center">
										Upload File
									</h2>
								</v-col>
							</v-row>
							<v-row justify="center" class="">
								<v-col cols="12" md="8">
									<h3 class="text-center">
										Upload the Excel file with data
									</h3>
								</v-col>
							</v-row>

							<!-- excel file input -->
							<v-row justify="center">
								<v-col cols="12" md="8">
									<v-file-input
										ref="fileInput"
										show-size
										label="Upload excel file"
										accept=".xls, .xlsx, .csv"
										:rules="[rules.required]"
										@change="excelCallback"
										clearable
										@click:clear="clearFileAndDataAndErrors()"
										:key="fileInputKey"
										:disabled="!excelData"
									>
									</v-file-input>
								</v-col>
							</v-row>

							<!-- error messages -->
							<v-row justify="center" v-if="excelError">
								<v-col cols="12" md="4">
									<h2 class="red--text">
										Import failed
									</h2>
								</v-col>
							</v-row>
							<v-row justify="center" v-if="excelError">
								<v-col cols="12" md="10">
									<h4 class="red--text">
										Some entries in the excel file have errors. Please check the following errors and re-upload with correct entries.
									</h4>
								</v-col>
							</v-row>
							<div v-if="excelErrorStrings.length && excelError">
								<v-row justify="center" v-for="(error, i) in excelErrorStrings" :key="i" >
									<v-col cols="12" >
										<h4 class="red--text">
											{{ error }}
										</h4>
									</v-col>
								</v-row>
							</div>
							<!-- download excel with incorrect entries -->
							<!-- ONLY WHEN ERROR RECEIVED FROM BACKEND -->
							<v-row justify="center" v-if="backendImportError">
								<v-col cols="12" md="10">
									<h4 class="red--text">
										Few errors have been encountered in the excel file, please download the file below to check the incorrect entries and re-upload the file with correct entries
									</h4>
								</v-col>
							</v-row>

							<!-- add note / count of correct and incorrect records -->
							<v-row justify="center" v-if="backendImportError">
								<v-col cols="12" md="10">
									<h4 class="red--text">
										Out of the {{ excelToJSON.length }} data rows provided via import, {{ backendResponse.success_count }} records have been successfully created. 
									</h4>
								</v-col>
							</v-row>

							<!-- container for errors from backend (if any) -->
							<div v-if="backendImportErrorStrings.length && backendImportError" justify="center">
								<v-row justify="center" v-for="(error, i) in backendImportErrorStrings" :key="i">
									<v-col cols="12" md="10">
										<h4 class="red--text">
											{{ error }}
										</h4>
									</v-col>
								</v-row>
							</div>

							<v-row justify="center" v-if="backendImportError">
								<v-col cols="12" md="3" sm="4">
									<!-- download backend incorrect data -->
									<download-excel
										:data="backendIncorrectData"
										:fields="getExportFields()"
										name="Students_ImportFailedData"
									>
										<v-btn
											class="text-right mr-1"
											:loading="export_in_progress"
											:disabled="export_in_progress || isLoading"
											color="primary"
											elevation="1"
											block
										>
											Download
											<v-icon right dark> mdi-export </v-icon>
											<template v-slot:loader>
												<span class="custom-loader">
													<v-icon light>mdi-cached</v-icon>
												</span>
											</template>
										</v-btn>
									</download-excel>
								</v-col>
							</v-row>

							<v-row justify="center">
								<v-col cols="12" md="3" justify="center">
									<v-btn
									width="100%"
										class="justify-center"
										color="primary"
										type="submit"
										v-model="form"
										:disabled="isLoading || !form"
										@click="submitData()"
									>
										Submit
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
			</v-col>
		</v-row>

		<!-- Instructions dialog -->
		<v-dialog v-model="showInstructionsDialog" persistent max-width="800px">
			<v-card>
				<v-card-title class="justify-center">
					<h3 class="grey--text text--darken-2"> Instructions </h3>
					<v-btn icon absolute top right color="dark" @click="showInstructionsDialog = false">
						<b>x</b>
					</v-btn>
				</v-card-title>

				<v-card-text>
					<v-container fluid class="mb-2">
						<v-row>
							<v-col>
								<h3>
								Custom import is useful to enter many records of institutional data at once.
								</h3>
							</v-col>
						</v-row>
						
						<v-row>
							<v-col>
								<h3>
								Allowed import files are '.xls', '.xlsx', '.csv' only.
								</h3>
							</v-col>
						</v-row>
						
						<v-row>
							<v-col>
								<h3>
								The headers in the sample file indicate the value that has to be entered. Use the instructions below to enter data in the file.
								</h3>
							</v-col>
						</v-row>
					</v-container>

					<v-divider></v-divider>

					<v-row>
						<v-col v-if="excelData">
							<v-data-table
								:headers="instructionHeaders"
								:items="excelData ? excelData.instructions : null"
								disable-pagination
								hide-default-footer
								class="my-1"
							>
								<template v-slot:item.accepted_values="{ item }">
									<div v-if="Array.isArray(item.accepted_values) && item.accepted_values.length">
										<!-- {{ item.accepted_values.toString().replaceAll(',', ', ') }} -->
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<v-chip
													small 
													color="blue" 
													outlined
													class="mr-1 mt-1 mb-1" 
													v-for="(val,i) in item.accepted_values" 
													@click="copyValue($event)"
													:key="i"
													v-on="on"
												>
													<h5>{{ val }}</h5>
												</v-chip>
											</template>
											<span> {{ copyFeedback }} </span>
										</v-tooltip>
									</div>
									<div v-else>
										{{ item.accepted_values }}
									</div>
								</template>

								<template v-slot:item.example_instructions="{ item }">
									<div v-html="item.example_instructions">
									</div>
								</template>

								<template v-slot:item.required="{ item }">
									<div>
										<v-icon :color="item.required ? 'green' : 'red'">
											{{ item.required ? 'mdi-check' : 'mdi-close' }}
										</v-icon>
									</div>
								</template>
							</v-data-table>
						</v-col>
					</v-row>

					<v-divider></v-divider>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- snackbar -->
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color" multi-line>
			{{ snackbar.message }}
			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar.visible = false">
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import api from "@api/index";
import { mapActions, mapGetters } from "vuex";
import endpoints from "@api/repository";
// import Helper from "@utils/misc";
import JsonExcel from "vue-json-excel";
import * as XLSX from 'xlsx';
import Helper from "@utils/misc";

export default {
	name: "ImportStudent",

	components: {
		downloadExcel: JsonExcel,
	},

	props: {
		"title" : {
			type: String,
			default: 'Import Excel'
		},
		"showDialog" : {
			type: Boolean,
			default: false
		},
		"endpoint" : {
			type: String,
			default: endpoints.importExcelStudents
		},
		"staticFile" : {
			type: String,
			default: endpoints.static.studentImportFileApp,
		}
	},

	data() {
		return {
			snackbar: {
				visible: false,
				message: "",
				color: "dark",
			},
			rules: {
				required: (value) => !!value || "Required",
			},

			export_in_progress: false,

			importEndpoint: null,		// endpoint for import process
			excelData: null,		// store all import related data
			
			// frontend validation, error data
			showImportExcelDialog: this.showDialog,
			form: false,
			excelFile: null,
			fileInputKey: 0,
			excelToJSON: null,
			excelIncorrectData: new Set(),
			excelIncorrectDataIndices: new Set(),
			excelError: false,
			excelErrorStrings: [],
			showInstructionsDialog: false,

			// backend error data
			backendResponse: null,
			backendImportError: false,
			backendIncorrectData: [],
			backendImportErrorStrings: [],
			copyFeedback: "Copy to clipboard",
			instructionHeaders: [
				{
          text: "Columns",
          align: "center",
          sortable: false,
          value: "column",
        },
				{
          text: "Accepted Values",
          align: "center",
          sortable: false,
          value: "accepted_values",
        },
				{
          text: "Examples and Instructions",
          align: "center",
          sortable: false,
          value: "example_instructions",
        },
				{
					text: "Required Field",
					align: "center",
					sortable: false,
					value: "required"
				}
			]
		};
	},

	computed: {
		...mapGetters([
			"currentUser",
			"accessToken",
			"isLoading",
			"currentInstitution",
		]),

		apiEssentials() {
			return {
				accessToken: this.accessToken,
				setLoading: this.setLoading,
				handleErrorsFunction: this.handleApiErrors,
			};
		},
	},

	methods: {
		...mapActions(["setLoading"]),

		// handle api errors
		async handleApiErrors(err) {
			this.setLoading(false);
			console.log(err);
			if (err.response) {
				this.snackbar.message = "Unable to process request";
				this.snackbar.color = "error";
				this.snackbar.visible = true;
			}
		},

		copyValue(e){
			navigator.clipboard.writeText(e.target.innerText);
			this.copyFeedback = "Copied!"
			setTimeout(() =>{
				this.copyFeedback = "Copy to clipboard"
			}, 800)
		},

		async getImportData() {
			// console.log(this.importEndpoint)
			var url = Helper.addUrlParams(this.importEndpoint,["src=app"])
			var response = await api.call(this.apiEssentials, url)

			if(response) {
				// console.log(response);
				this.excelData = response
			}
		},

		// export fields for excel files
		getExportFields() {
			let export_fields = {}
			this.excelData.headers.forEach(header => {
				// console.log(header)
				export_fields[header.title] = header.title
			})

      return export_fields
    },

		// close import dialog func with emit to parent page
		closeDialog(showDialog) {
			if(!showDialog) {
				this.showImportExcelDialog = false
				this.clearFileAndDataAndErrors()
				this.$emit('closeImportDialog', showDialog)
			}
		},

		// catch the excel file from input and process
		excelCallback(file) {
			if(file) {
				this.excelFile = file
				this.convertExcelToJSON()
			}
		},

		async convertExcelToJSON() {
			if(this.excelFile){		// if excel file received
				let fileReader = new FileReader();
				fileReader.readAsBinaryString(this.excelFile);
				fileReader.onload = (event) => {
					let data = event.target.result;
					let workbook = XLSX.read(data, {type:"binary", cellText: false, cellDates: true});
					workbook.SheetNames.forEach(sheet => {
						let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {defval:"", raw: false, dateNF: 'YYYY-MM-DD'});
						this.excelToJSON = rowObject
						// console.log("excelToJson", this.excelToJSON);
					});
				}
			}
		},

		clearFileAndDataAndErrors() {
			this.excelError = false
			this.$refs.form.reset()
			// this.$refs.fileInput.reset()
			this.fileInputKey++
			this.excelFile = null
			this.excelToJSON = null
			this.excelIncorrectData.clear()
			this.excelIncorrectDataIndices.clear()
			this.excelErrorStrings = []

			this.backendResponse = null;
			this.backendImportError = false
			this.backendImportErrorStrings = []
			this.backendIncorrectData = []
		},

		// check if all uploaded data is correct
		validateData() {
			if(this.excelToJSON && this.excelData.headers.length) {		// if json from excel is obtained

				// get headers list from excel-headers
				let headerNamesList = this.excelData.headers.map(data => data.title)

				for(let i=0; i<this.excelToJSON.length; i++) {
					headerNamesList.forEach(header => {
						if(!this.excelToJSON[i][header]) {
							this.excelToJSON[i][header] = this.excelToJSON[i][header + '*']
							delete this.excelToJSON[i][header + '*']
						}
					})
					
					// compare length of headers with received file -> must be same
					if(Object.keys(this.excelToJSON[i]).length != headerNamesList.length) {		// if headers length is same
						this.excelError = true
						this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
						this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
						this.excelErrorStrings.push(`Error: Entries don't have all the headers.`)
						this.form = false

						continue
						// consider break here
					}

					// check if all keys are present
					for(let j=0; j<headerNamesList.length; j++) {
						var header = headerNamesList[j]
						if(!Object.prototype.hasOwnProperty.call(this.excelToJSON[i], header)) {		// check if headerName is present
							// console.log(header, "not available");
							this.excelError = true
							this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
							this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index/row number of excel
							this.excelErrorStrings.push(`Row ${i+2} : Data doesn't have all the required values.`)
							this.form = false
						}
					}

					// iterate over headers and check values
					for(let j=0; j<this.excelData.headers.length; j++) {
						let currHeaderObj = this.excelData.headers[j]		// store current default header object for comparison

						if(!currHeaderObj.required && !this.excelToJSON[i][currHeaderObj.title]) {
							continue
						}

						if(currHeaderObj.type == "Number") {
							var check = Number(this.excelToJSON[i][currHeaderObj.title])
							if(isNaN(check)) {
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value doesn't have the required data type.`)
								this.form = false
							}
						}
						else if(currHeaderObj.type == "String") {
							if(typeof(this.excelToJSON[i][currHeaderObj.title]) != 'string') {
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value doesn't have the required data type.`)
								this.form = false
							}
						}

						// check if any value is empty, if required
						if(typeof(this.excelToJSON[i][currHeaderObj.title]) == "string") {		// if value is a string
							if(currHeaderObj.required && !String(this.excelToJSON[i][currHeaderObj.title]).length) {
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value is required.`)
								this.form = false
							}
						}
						else {
							if(currHeaderObj.required && !this.excelToJSON[i][currHeaderObj.title]) {
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value is required.`)
								this.form = false
							}
						}

						// check maxlength, if any
						if(Object.prototype.hasOwnProperty.call(currHeaderObj, 'max_length')) {			// check if currrent header object has max_length requirement
							if(String(this.excelToJSON[i][currHeaderObj.title]).trim().length > currHeaderObj.max_length) {
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value crossed the maximum allowed length (${currHeaderObj.max_length}).`)
								this.form = false
							}
						}

						// check minlength, if any
						if(Object.prototype.hasOwnProperty.call(currHeaderObj, 'min_length')) {		// check if currrent header object has min_length requirement
							if(String(this.excelToJSON[i][currHeaderObj.title]).trim().length < currHeaderObj.min_length) {
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value doesn't have the minimum required length (${currHeaderObj.min_length}).`)
								this.form = false
							}
						}

						// check allowed_values, if any
						if(Object.prototype.hasOwnProperty.call(currHeaderObj, 'allowed_values')) {		// check if currrent header object has allowed_values requirement
							if(!currHeaderObj.allowed_values.find(data => data == String(this.excelToJSON[i][currHeaderObj.title]).trim())) {		// check if the value lies in allowed_values items
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value doesn't fulfill the allowed values requirement.`)
								this.form = false
							}
						}

						// check for regex pattern, if any
						if(Object.prototype.hasOwnProperty.call(currHeaderObj, 'regex')) {		// check if currrent header object has a regex check
							let data = String(this.excelToJSON[i][currHeaderObj.title]).trim()
							let regEx = currHeaderObj.regex

							if(!regEx.test(data)) {		// check if the regex pattern matching fails
								this.excelError = true
								this.excelIncorrectData.add(this.excelToJSON[i])		// store incorrect data object
								this.excelIncorrectDataIndices.add(i+2)		// store incorrect data index
								this.excelErrorStrings.push(`Row ${i+2} : ${currHeaderObj.title} value does not follow the valid format.`)
								this.form = false
							}
						}
					}
				}
			}
		},

		// trigger if backend error is received
		triggerBackendError() {
			// error strings list operations
			if(this.backendResponse.error_messages.length) {
				this.backendImportError = true
				this.backendImportErrorStrings = this.backendResponse.error_messages

				var errorStrings = []			// store formatted error strings
				this.backendResponse.error_messages.forEach(e => {
					var stringify = JSON.stringify(e) 	// convert to string
					var rowNo = stringify.split(':')[0].replace('"','').replace('{','').replace('"','')		// extracts 'Row #'
					var parse = JSON.parse(stringify)		// create obj from string
					
					if(Object.prototype.hasOwnProperty.call(parse), String(rowNo)) {		// if the obj has row # as a key
						Object.values(e[String(rowNo)]).forEach(obj => {
							errorStrings.push(`${rowNo} : ${obj.toString()}`)
						})
					}
				})
				this.backendImportErrorStrings = errorStrings
			}
			
			if(this.backendResponse.failed_list.length) {
				this.backendIncorrectData = this.backendResponse.failed_list
			}
		},

		// check if validations are passed and submit data to server
		async submitData() {
			this.validateData()		// validate excel data and set error lists (if errors found)
			
			// if no frontend errors are encountered
			if(!this.excelErrorStrings.length) {
				// api call here to post data
				var url = this.importEndpoint
				var data = {
					"data": this.excelToJSON
				}
				var response = await api.call(this.apiEssentials, url, api.Methods.POST, data)

				// if backend import error received, set backend error trigger true, backend errors list
				if(response) {
					this.backendResponse = response
					this.triggerBackendError()
				}

				// show snackbar alert
				if(!this.backendImportError) {
					this.snackbar.message = `Excel uploaded successfully! All of the ${this.backendResponse.success_count} records have been imported!`
					this.snackbar.visible = true
					this.snackbar.color = "success"
					
					this.$emit('successfullyImported', true)
					
					this.closeDialog(false)
					
					if(this.currentInstitution.stage == 0) 
						window.location.reload()
				}
				else {
					this.form = false
					this.snackbar.message = "Excel import error!"			// snackbar alert
					this.snackbar.visible = true
					this.snackbar.color = "error"
				}
			}
			else {
				// show snackbar
				this.snackbar.message = "Excel import error!"			// snackbar alert
				this.snackbar.visible = true
				this.snackbar.color = "error"

				// clear excel file and json data (func)
			}
		},
	},

	created() {
		this.importEndpoint = this.endpoint
		this.getImportData()				
	},

	// watch: {
		// showDialog: {
			// async handler() {
			// 	this.showImportExcelDialog = this.showDialog
			// 	if(this.showImportExcelDialog) {
			// 		this.importEndpoint = this.endpoint
			// 		if(this.importEndpoint) {
			// 			this.getImportData()
			// 		}
			// 	}
			// }
		// }
	// }
}
</script>

<style></style>